import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import Synth from './Synth';
import { LuArrowLeftSquare } from "react-icons/lu";
import { LuArrowRightSquare } from "react-icons/lu";

const App = () => {
  const [appState, setAppState] = useState("idle");
  const [transcript, setTranscript] = useState("");
  
  const [currentState, setCurrentState] = useState("welcome_state");

  // window.cyclestate: controls the cycle: -1 (disabled), 0 (listening), 1 (processing)

  const recognitionRef = useRef(null);
  const wakeWordRecognitionRef = useRef(null);
  
  const [chatHistory, setChatHistory] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const [showSpeechBubble, setShowSpeechBubble] = useState(false);
  const [speechBubbleTimeout, setSpeechBubbleTimeout] = useState(3000); // Duration in milliseconds

  const [showSpinner, setShowSpinner] = useState(false);
  

  const WAKE_UP_WORD="garbiñe";

  const bing = new Audio("/static/audio/bing.mp3");
  
  function get_current_domain(){
    let current_domain=window.location.href.replace("//","|").split("/")[0].replace("|","//");
    current_domain=current_domain.replace(":3000",":5000");

    return current_domain;

  }

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
  }

  const MYUID=uuidv4();
  

  const handleStop = () => {
    window.location.reload(); // Reloads the current page
  };

  function start_listening(){
    setTimeout(
        ()=>{
          recognitionRef.current.start();
        }
    , 500)
    
    setAppState("listening");
    window.cycleState=0;
  }

  function stop_listening(){
    recognitionRef.current.stop();
    window.cycleState=-1;
    //setAppState("idle");
  }

  function disable_spinner(){
    setAppState("idle");
    setShowSpinner(false);
    window.cycleState=-1;
  }

  const toggleRecording = () => {

    //bing.play();

    let msg = "Te escucho!!";

    if (appState=="listening"){
      bing.play();
      msg="";
    }

    speak(msg, ()=>{
      try {
        if (appState === "idle") {
          start_listening();
        } else if (appState === "listening") {
          stop_listening();
          setTimeout(
            ()=>{                            
              setTranscript("");
              setAppState("idle");
              window.cycleState=-1;
              setShowSpinner(false);
                }
          ,500);
        }
      } catch (error) {
        console.error("Speech recognition error:", error);
      }          
    }
    );
         
    


  };


  useEffect(() => {

    Synth.checkSynth();
    Synth.init();

    console.log(MYUID);

    // Main speech recognition setup

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const SpeechGrammarList =
      window.SpeechGrammarList || window.webkitSpeechGrammarList;      
    if (SpeechRecognition && !recognitionRef.current) {
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = false;

      recognitionRef.current.lang = "es-ES";
      //console.log(recognitionRef.current.lang);

      const grammar = "Garbiñe;";    
      let speechRecognitionList = undefined;
      if (SpeechGrammarList){
        speechRecognitionList = new SpeechGrammarList();
        speechRecognitionList.addFromString(grammar, 1);      
        recognitionRef.current.grammars = speechRecognitionList;

      }
      


      recognitionRef.current.onresult = (event) => {
        const lastResultIndex = event.results.length - 1;
        let transcriptResult = event.results[lastResultIndex][0].transcript;

        transcriptResult = transcriptResult.replaceAll("cariño", "Garbiñe").replaceAll("Cariño", "Garbiñe");;
        setTranscript(transcriptResult);
        setAppState("playing");
        setShowSpeechBubble(true);
        setTimeout(() => setShowSpeechBubble(false), speechBubbleTimeout);
        stop_listening();
        fetchResponseFromLLM(transcriptResult);
      };

      recognitionRef.current.onend = () => {
        //console.log("end recognition");
        //console.log("cyclestate:" + window.cycleState);

        if (transcript.replaceAll(" ","").length<1 && window.cycleState==0){
            start_listening();
        } else {
          window.cycleState=1;
          // We consider that we have something to analyze
          setShowSpinner(true);
        }        
        // Optional: Handle end of recognition
      };
    }
  }, []);


  const fetchResponseFromLLM = async (text) => {

    console.log(text);
    const maxLength=300;
    text = text.substring(0, maxLength);

    window.cycleState=1;

    let current_domain=get_current_domain();
    
    try {
      const response = await fetch(
        current_domain + "/api/chat",
        //"https://two-melons-greet.loca.lt/api/chat",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            data: {
              user_request: text,
              session_id: MYUID
            }
          }),
        }
      );
      const data = await response.json();

      let mensaje=data.result.answer.bot_answer;
      console.log(data.result.answer);
      console.log(data.result);
      console.log(mensaje);
      let new_state = data.result.state_machine_state;

      if(data.result.answer.proposed_action=="upload_photo"){
        $("#takePictureModal").modal("show");
      }
      setCurrentState(new_state);
      //alert(data.result.content);

      setChatHistory((prevHistory) => [
        ...prevHistory,
        { query: text, response: mensaje },
      ]);
        speak(mensaje, disable_spinner);
    } catch (error) {
      let msg = ("Error communicating with LLM:", error);      
      speak("Error!", undefined);
      start_listening();
      console.error(msg);
    }
  };  

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const speak = (text, callback) => {
    if (!callback) {
      function callback(){ console.log("Finished speaking")}
    }    
    Synth.speak(text, callback );    
  };

  function setUIKit(){
      var bar = document.getElementById('js-progressbar');


      UIkit.upload('.js-upload', {

          url: get_current_domain()+'/api/image',
          allow : '*.(jpg|jpeg|gif|png)',
          type: "application/json",
          multiple: false,

          beforeSend: function () {
              //console.log('beforeSend', arguments);
              $("#takePictureModal").modal("hide");
              $("#loading_modal").show();
              UIkit.modal('#loading_modal').show();
          },
          beforeAll: function () {
              //console.log('beforeAll', arguments);
          },
          load: function () {
              //console.log('load', arguments);
          },
          error: function () {
              console.log('error', arguments);
          },
          complete: function () {
              //console.log('complete', arguments);
          },

          loadStart: function (e) {
              //console.log('loadStart', arguments);

              bar.removeAttribute('hidden');
              bar.max = e.total;
              bar.value = e.loaded;
          },

          progress: function (e) {
              //console.log('progress', arguments);

              bar.max = e.total;
              bar.value = e.loaded;
          },

          loadEnd: function (e) {
              //console.log('loadEnd', arguments);

              bar.max = e.total;
              bar.value = e.loaded;
          },

          completeAll: async function (e) {
              //console.log('completeAll', arguments);

              setTimeout(function () {
                  bar.setAttribute('hidden', 'hidden');
              }, 1000);

              UIkit.modal('#loading_modal').hide();
              $("#loading_modal").hide();
              //alert('Upload Completed. Result: ' + e.response);

              let data=JSON.parse(e.response);
              let destination=data["destination"];
              let bot_answer=data["bot_answer"];

              let msg = bot_answer; //"Deberías llevar eso al " + destination;
              speak(msg, undefined);              
              const response =  await fetch(
                get_current_domain() + "/api/chat",
                //"https://two-melons-greet.loca.lt/api/chat",
                {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    data: {
                      user_request: "RESET_STATE",
                      session_id: MYUID,
                    }
                  }),
                }
              );
              
              let response_data=await response.json();
              console.log(response_data.result.content);
              console.log(response_data.result.state_machine_state);
              let new_state = response_data.result.state_machine_state;      
              setCurrentState(new_state);                       
          }

      });    
  }  

  setTimeout( ()=>{
    setUIKit();
  }, 1000);


  return (
    <div className="container">

      <div className="modal fade" id="takePictureModal" tabindex="-1" aria-labelledby="takePictureModal" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Realiza foto para clasificar</h5>
            </div>
            <div className="modal-body">

                <form className="uk-width-1-1">
                    <fieldset className="uk-fieldset">
                        <div className="js-upload uk-placeholder uk-text-center">
                              <span uk-icon="icon: cloud-upload"></span>
                              <div uk-form-custom>
                                  <input type="file" name="files" id="files" style={{fontSize: "12px"}}/>
                              </div>
                        </div>
                    </fieldset>
                </form>

            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>

      
        <div class="app2-header" style={{position: "absolute", top: "20%",  fontFamily: "Orbitron"}}>
            <h1 style={{fontSize: "230%", fontFamily: "Orbitron", zIndex: 20, position: "relative"}}><b>GARBIÑE AI</b></h1> 
        </div>
      


      <div className={`speech-bubble ${showSpeechBubble ? "visible" : ""}`} style={{zIndex:99999}}>
        {transcript}
      </div>
      
      
      <div className={`app-state-indicator ${appState}`} style={{zIndex:99}}>
        <div className="listening-indicator">
          {appState !== "idle" && (
            <>              
              <span></span>
              <span></span>
              <span></span>
            </>
          )}
        </div>
        {appState === "listening" && (
          <div className="listening-bubble" style={{zIndex:99}}>... estoy escuchando...</div>
        )}
        <a
          className={`record-btn ${appState}`}
          onClick={toggleRecording}
          style={{height: "260px", width: "260px"}}
          disabled={appState !== "idle"}
        >
          <img src="/static/img/garbiñe2.png" style={{width: "210px", marginLeft: "20px", 
                marginTop: "-20px", borderRadius: "70px"}}></img>                      
          {showSpinner && <div className="spinner"></div>}
        </a>
        <button className="stop-btn" onClick={handleStop} style={{zIndex:999, color: "white"}}>
          Reiniciar
        </button>
      </div>


      <div className={`chat-sidebar ${isChatOpen ? "open" : ""}`}>
        <div className="chat-content">
          {chatHistory.map((entry, index) => (
            <div key={index} className="chat-entry">
              <div className="user-query">Usuario: {entry.query}</div>
              <div className="system-response">Garbiñe: {entry.response}</div>
            </div>
          ))}
        </div>
      </div>

      <button className="chat-toggle-button" onClick={toggleChat}>
        {isChatOpen ? <><LuArrowLeftSquare size={30}/> <br/><span style={{fontSize: "9px"}}>Cerrar</span> </> : <><LuArrowRightSquare size={30}/> <br/><span style={{fontSize: "9px"}}>Histórico</span> </>        }
      </button>
      
      <footer className="app-footer" style={{zIndex:99999}}>
        <center><p class="app-subtext" style={{background: "rgba(0,0,0,0.3)", width: "98%"}}>Haz click en el círculo para activar la escucha</p></center>        
        Creado por ANTARES
      </footer>

      <div id="loading_modal" class="uk-flex-top" uk-modal style={{display: "none", zIndex: 500, maxWidth: "98%"}}>
          <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
              <center>... déjame pensar un poco ...</center>   
              <br/>
              <center><div uk-spinner="ratio: 3"></div></center>   

              <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
          </div>
      </div>    

    </div>
  );
};

export default App;
